import axios from '@/common/axios'
import urls from '@/common/urls'
import tips from '@/common/tips'
import html2canvas from 'html2canvas'

async function uploadFile(file,reg='jpg|jpeg|png|PNG|JPG',key,url='',item,headers){
  if(!file) return;
  let RegObj = new RegExp(`^(${reg})$`)
  let type = file.name.split('.').slice(-1)
  if(!RegObj.test(type)){
    tips.error({text:`类型必须是${reg.replace(/\|/g,',')}`})
    return '';
  }
  let data = await requestFile(file,key,url,item,headers)
  return url ? data : data.url
}


async function requestFile(file,key='file',url='',item={},headers){
  let data = new FormData()
  data.append(key,file)
  for(let i in item){
    data.append(i,item[i])
  }
  url = url || urls.uploads.uploadFile;
  return new Promise(resolve=>{
    axios.post(url,data,headers).then(res=>{
      resolve(res)
    }).catch(err=>{
      resolve({url:""})
    })
  })
}

async function saveImg(dom){
  return new Promise(resolve=>{
    html2canvas(dom).then(canvas => {
      let file = canvas.toDataURL("image/png")
      resolve(file)
    });
  })
}
async function savePage(dom,headers){
  let file = await saveImg(dom);
  let data = await requestFile(file,'file',urls.uploads.uploadBase64,headers);
  return data.url;
}

async function uploadBase64(file,url,item,headers){
  let data = await requestFile(file,'file',url || urls.uploads.uploadBase64,item,headers);
  return data.url;
}

export default {
  uploadFile,
  requestFile,
  uploadBase64,
  savePage
}